/* eslint-env browser */
import axios from 'axios';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  actions as moduleActions,
  modules,
  renderer,
  shared,
} from 'cms-modules';
import { sortOptions } from 'cms-modules/src/shared/sortOptions/settings';

import querystring from 'querystring-es3';
import appSettings from '../../settings';
import { actions } from '../actions/config';
import { actions as flagActions } from '../actions/flags';
import { actions as indexedPagesActions } from '../actions/indexedPages';

const { matchesPathname } = renderer;

const isModuleSearchableAndIndexable = mod =>
  mod.searchable &&
  mod.getIndexContent &&
  typeof mod.getIndexContent === 'function';

const indexSearchablePageModules = ({ pages }) =>
  pages
    .reduce(
      (list, { modules: mod = [], relativePath }) => [
        ...list,
        ...mod
          .filter(m => isModuleSearchableAndIndexable(modules[m.id] || {}))
          .map(({ config: cf, instanceId, id }) =>
            modules[id].getIndexContent({
              config: cf,
              relativePath,
              instanceId,
            }),
          ),
      ],
      [],
    )
    .reduce((list, rest) => [...list, ...rest], []);

export const featureFlagsWithPathConfig = features => {
  const configProp = (features.configSelector || []).find(matchesPathname);
  return configProp ? { ...features[configProp.prop] } : features;
};

const selectConfigByPath = config => {
  return {
    ...config,
    global: {
      ...config.global,
      featureFlags: featureFlagsWithPathConfig(config.global.featureFlags),
    },
  };
};

function* resetIfExpired() {
  const {
    customerLogin: { actions: authenticationActions, selectors },
  } = shared;
  const loggedIn = yield select(state => selectors.tokenExists(state.shared));
  const isTokenValid = yield select(state =>
    selectors.isTokenValid(state.shared),
  );
  // need logged in check or site goes haywire!
  if (loggedIn && !isTokenValid) {
    yield put(authenticationActions.signOut());
  }
}

const injectSiteIcon = siteConfig => {
  const icoIcon = document.createElement('link');
  icoIcon.setAttribute('rel', 'shortcut icon');
  const favIcon = siteConfig.global && siteConfig.global.siteIcons.ico;
  icoIcon.setAttribute('href', favIcon);
  document.head.appendChild(icoIcon);

  const appleTouchIcon = document.createElement('link');
  appleTouchIcon.setAttribute('rel', 'apple-touch-icon');
  appleTouchIcon.setAttribute('type', 'image/png');
  const appleTouch =
    siteConfig.global && siteConfig.global.siteIcons.appleTouch;
  appleTouchIcon.setAttribute('href', appleTouch);
  appleTouchIcon.setAttribute('sizes', '180x180');
  document.head.appendChild(appleTouchIcon);

  const maskIcon = document.createElement('link');
  maskIcon.setAttribute('rel', 'mask-icon');
  const maskIconSrc =
    siteConfig.global && siteConfig.global.siteIcons.maskIcon.src;
  const maskIconColor =
    siteConfig.global && siteConfig.global.siteIcons.maskIcon.color;
  maskIcon.setAttribute('href', maskIconSrc);
  maskIcon.setAttribute('color', maskIconColor);
  document.head.appendChild(maskIcon);

  const faviconPNGx16 = document.createElement('link');
  faviconPNGx16.setAttribute('rel', 'icon');
  faviconPNGx16.setAttribute('type', 'image/png');
  const iconPNG16 =
    siteConfig.global && siteConfig.global.siteIcons.faviconPNGx16;
  faviconPNGx16.setAttribute('href', iconPNG16);
  faviconPNGx16.setAttribute('sizes', '16x16');
  document.head.appendChild(faviconPNGx16);

  const faviconPNGx32 = document.createElement('link');
  faviconPNGx32.setAttribute('rel', 'icon');
  faviconPNGx32.setAttribute('type', 'image/png');
  const iconPNG32 =
    siteConfig.global && siteConfig.global.siteIcons.faviconPNGx32;
  faviconPNGx32.setAttribute('href', iconPNG32);
  faviconPNGx32.setAttribute('sizes', '32x32');
  document.head.appendChild(faviconPNGx32);
};

const addGoogleAnalytics = siteConfig => {
  const {
    global: { analytics },
  } = siteConfig;
  //  if a custom Consent Controller exists, let it decide if Analytics is permitted
  if (
    siteConfig.global.modules.find(m => m.id === 'ConsentController') !==
    undefined
  ) {
    if (window.ga) window.ga('remove');
    const gtmTag = document.getElementById('googleTagManagerScript');
    if (gtmTag) gtmTag.remove();
    document.cookie = `_gid=; Max-Age=-99999999;`;
    document.cookie = `_ga=; Max-Age=-99999999;`;
    document.cookie = `_gat=; Max-Age=-99999999;`;
    return;
  }

  if (analytics && analytics.googleAnalyticsTrackingId) {
    const globalSiteTagManagerScript = document.createElement('script');
    globalSiteTagManagerScript.async = true;
    globalSiteTagManagerScript.setAttribute('id', 'googleTagManagerScript');
    globalSiteTagManagerScript.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        // eslint-disable-next-line prefer-rest-params
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', analytics.googleAnalyticsTrackingId);
    };
    globalSiteTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${
      analytics.googleAnalyticsTrackingId
    }`;
    document.head.appendChild(globalSiteTagManagerScript);
  }
};

function* setPreferences(config, hasDualCurrency) {
  const inventoryWithDealerConfig = inventory => {
    const inventoryProp = (inventory.inventorySelector || []).find(
      matchesPathname,
    ) || { prop: 'defaultSortKey' };
    return inventory[inventoryProp.prop];
  };
  yield put(
    shared.sessionPreferences.actions.updateMultipleSessionPreferences(
      [
        {
          key: 'searchSort',
          value:
            sortOptions[inventoryWithDealerConfig(config.global.inventory)],
        },
        {
          key: 'shortlistSort',
          value:
            sortOptions[config.global.inventory.defaultShortlistSortKey] ||
            null,
        },
        {
          key: 'language',
          value: config.global.inventory.locale,
        },
        hasDualCurrency && {
          key: 'currency',
          value: config.global.inventory.currencyCode,
        },
        {
          key: 'disableCurrencyPopup',
          value: config.global.inventory.disableCurrencyPopup,
        },
      ].filter(item => item && item.value),
    ),
  );
}

function* loadConfigFromApi(url) {
  const result = yield call(axios.get, url);
  const config = result.data;

  const hasDualCurrency =
    config.global.inventory.currencyCode &&
    config.global.inventory.secondaryCurrency;

  yield setPreferences(config, hasDualCurrency);
  const historyPath = yield select(
    state => state.router.history && state.router.history.location.pathname,
  );
  const queryParams = yield select(state => state.router.params);
  const platformModeParams = yield select(
    state => state.config.config.global.platformModeParams,
  );

  const filteredParams =
    queryParams &&
    platformModeParams &&
    Object.keys(queryParams)
      .filter(key => platformModeParams.includes(key))
      .reduce((obj, key) => {
        // eslint-disable-next-line no-param-reassign
        obj[key] = queryParams[key];
        return obj;
      }, {});

  const path = filteredParams
    ? `${historyPath}?${querystring.stringify(filteredParams)}`
    : historyPath;

  if (path) {
    // navigation will sort the language code in the path
    yield put(moduleActions.router.actions.navigate(path));
  }

  const configForPath = selectConfigByPath(config);

  yield put(actions.loadConfig(configForPath));
  yield call(injectSiteIcon, configForPath);
  yield call(addGoogleAnalytics, configForPath);
  const indexedPagesContent = yield call(
    indexSearchablePageModules,
    configForPath,
  );
  yield put(indexedPagesActions.loadIndexedPages(indexedPagesContent));
  yield hasDualCurrency &&
    put(
      shared.currencyConversion.actions.getExchangeRates(
        configForPath.global.inventory.currencyCode,
      ),
    );

  try {
    const response = yield call(
      axios.get,
      'https://d2s8xxl8tadw99.cloudfront.net/transformed.json',
    );
    const featureFlags = response.data;

    yield put(flagActions.setFlags({ changeFlags: featureFlags }));
  } catch (error) {
    console.error('Error fetching or setting flags:', error);
  }
}

const injectFontsCss = fontsCssUrl => {
  const fontsCss = document.createElement('link');
  fontsCss.setAttribute('href', fontsCssUrl);
  fontsCss.setAttribute('rel', 'stylesheet');
  fontsCss.setAttribute('type', 'text/css');
  document.head.appendChild(fontsCss);
};

function* loadRemoteConfig(siteId, locale, defaultLocale) {
  try {
    const territory = yield select(state => state.config.settings.territory);
    const configUrlForLanguage = territory
      ? `${appSettings.configBaseUrl}/${territory}/${siteId}/${locale}.json`
      : `${appSettings.configBaseUrl}/${siteId}/${locale}.json`;
    yield call(loadConfigFromApi, configUrlForLanguage);
  } catch (error) {
    /* eslint-disable no-console */
    yield call(
      console.warn,
      `Failed to load settings for site - ${error.message}`,
    );
    if (defaultLocale && defaultLocale !== locale) {
      yield put(
        shared.sessionPreferences.actions.updateSessionPreferences(
          'language',
          defaultLocale,
        ),
      );

      // eslint-disable-next-line no-use-before-define
      yield changeLanguage({ payload: defaultLocale });
    }
  }
}

function getLanguageForTerritory(territory, language) {
  const territoryLanguageMap = {
    na: {
      en_gb: 'en_us',
    },
    row: {
      en_us: 'en_gb',
    },
  };
  return territoryLanguageMap[territory][language] || language;
}

function* loadPrintedSettings() {
  let domain;

  if (appSettings.configMarketByUrl) {
    if (window.location.pathname !== '') {
      const urlParts = window.location.pathname
        .replace(/^\/|\/$/g, '')
        .split('/');

      if (urlParts && urlParts.length) {
        const [urlMarket] = urlParts;
        domain = `${urlMarket}.${window.location.hostname.replace('www.', '')}`;
      }
    }
  } else {
    domain = window.location.hostname;
  }

  const settingsUrl = `${appSettings.configBaseUrl}/${domain}/avl.json`;
  try {
    const result = yield call(axios.get, settingsUrl);
    const { siteId, locale, staticCss } = result.data;
    const urlArrayPath = window.location.pathname.split('/');
    const languageArray = urlArrayPath.filter(
      item => item.match(/^[a-z]{2}$/) !== null,
    );
    const urlLocale =
      languageArray.length <= 1
        ? (window.location.pathname.match(/\/[a-z]{2}_[a-z]{2}\//) || [
            '',
          ])[0].replace(/\//g, '')
        : `${languageArray[1]}_${languageArray[0]}`;

    const previousLocale = yield select(state => state.config.settings.locale);
    const browserLocale = window.navigator.language
      .toLocaleLowerCase()
      .replace(/-/g, '_');
    // locale from url beats one from local storage which beats browser preference which beats the default for the site
    const activeLocale = urlLocale || previousLocale || browserLocale || locale;

    yield put(
      shared.sessionPreferences.actions.updateSessionPreferences(
        'language',
        activeLocale,
      ),
    );

    const territory = localStorage.getItem('territory');
    const { territory: defaultTerritory } = result.data;

    let language;
    let defaultTerritoryLocale;

    if (territory && territory !== defaultTerritory) {
      language = getLanguageForTerritory(territory, activeLocale);
      defaultTerritoryLocale = getLanguageForTerritory(territory, locale);
    } else if (defaultTerritory) {
      language = getLanguageForTerritory(defaultTerritory, activeLocale);
      defaultTerritoryLocale = getLanguageForTerritory(
        defaultTerritory,
        locale,
      );
    } else {
      language = activeLocale;
      defaultTerritoryLocale = locale;
    }

    yield put(
      actions.loadSettings({
        ...result.data,
        modelGroupsUrl: `${appSettings.configBaseUrl}/${
          appSettings.configBaseUrl.indexOf('localhost') === -1
            ? `${domain}/`
            : ''
        }`,
        locale: language,
        defaultLocale: defaultTerritoryLocale,
        hashRouting: appSettings.configMarketByUrl,
        ...(territory || defaultTerritory
          ? { territory: territory || defaultTerritory }
          : {}),
      }),
    );

    yield all([
      call(injectFontsCss, staticCss),
      call(loadRemoteConfig, siteId, language, defaultTerritoryLocale),
    ]);
  } catch (error) {
    /* eslint-disable no-console */
    yield call(
      console.warn,
      `Failed to load settings for site - ${error.message}`,
    );
    /* eslint-enable no-console */
  }
}

function* loadPreviewSettings() {
  const urlParams = new URLSearchParams(window.location.search);
  const languageFromQueryOrCache =
    urlParams.get('defaultLanguage') ||
    sessionStorage.getItem('defaultLanguage');
  const environmentFromQueryOrCache =
    urlParams.get('environment') || sessionStorage.getItem('environment');
  const siteIdFromQueryOrCache =
    urlParams.get('siteId') || sessionStorage.getItem('siteId');

  sessionStorage.setItem('defaultLanguage', languageFromQueryOrCache);
  sessionStorage.setItem('environment', environmentFromQueryOrCache);
  sessionStorage.setItem('siteId', siteIdFromQueryOrCache);

  const settingsUrl = `${
    appSettings.configBaseUrl
  }/${siteIdFromQueryOrCache}/avl.json`;
  try {
    const result = yield call(axios.get, settingsUrl);
    yield put(actions.loadSettings(result.data));
    const fontsCssUrl = `${
      appSettings.configBaseUrl
    }/${siteIdFromQueryOrCache}/fonts.css`;
    yield call(injectFontsCss, fontsCssUrl);
    const configUrlForLanguage = `${
      appSettings.configBaseUrl
    }/${siteIdFromQueryOrCache}/${languageFromQueryOrCache}.json`;
    yield call(loadConfigFromApi, configUrlForLanguage);

    if (!sessionStorage.getItem('initialNavigateCompleted')) {
      yield put(
        moduleActions.router.actions.navigate(window.location.pathname),
      );
      sessionStorage.setItem('initialNavigateCompleted', true);
    }
  } catch (error) {
    /* eslint-disable no-console */
    yield call(
      console.warn,
      `Failed to load settings for site - ${error.message}`,
    );
    /* eslint-enable no-console */
  }
}

function* setFlags() {
  yield put(flagActions.setFlags(appSettings.flags || {}));
}

function* changeLanguage({ payload: locale }) {
  const siteId = yield select(state => state.config.settings.siteId);
  const configSettings = yield select(state => state.config.settings);
  yield put(
    actions.loadSettings({
      ...configSettings,
      locale,
    }),
  );
  yield call(loadRemoteConfig, siteId, locale);
}

function* changeTerritory({ payload }) {
  const siteId = yield select(state => state.config.settings.siteId);
  const configSettings = yield select(state => state.config.settings);
  localStorage.setItem('territory', payload.territory);

  yield put(
    actions.loadSettings({
      ...configSettings,
      territory: payload.territory,
    }),
  );
  try {
    const newTerritoryUrl = `${appSettings.configBaseUrl}/${
      payload.territory
    }/${siteId}/${payload.language}.json`;
    yield call(loadConfigFromApi, newTerritoryUrl);
  } catch (error) {
    /* eslint-disable no-console */
    yield call(
      console.warn,
      `Failed to load settings for site - ${error.message}`,
    );
    /* eslint-enable no-console */
  }
}

function* loadSettings() {
  switch (appSettings.configSource) {
    case 'printed':
      yield loadPrintedSettings();
      yield setFlags();
      yield resetIfExpired();
      break;
    case 'preview':
      yield loadPreviewSettings();
      break;
    default:
      /* eslint-disable no-console */
      yield call(console.warn, 'unknown settings source');
      /* eslint-enable no-console */
      break;
  }
}

export default function* configLoaderSaga() {
  yield call(loadSettings);
  yield takeLatest(
    moduleActions.language.constants.changeLanguage,
    changeLanguage,
  );
  yield takeLatest(
    moduleActions.territory.constants.changeTerritory,
    changeTerritory,
  );
}
